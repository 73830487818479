exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../../../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-tag-tsx": () => import("./../../../src/pages/tag.tsx" /* webpackChunkName: "component---src-pages-tag-tsx" */),
  "component---src-templates-about-hair-about-hair-tsx": () => import("./../../../src/templates/about-hair/about-hair.tsx" /* webpackChunkName: "component---src-templates-about-hair-about-hair-tsx" */),
  "component---src-templates-about-hair-sac-hakkinda-tsx": () => import("./../../../src/templates/about-hair/sac-hakkinda.tsx" /* webpackChunkName: "component---src-templates-about-hair-sac-hakkinda-tsx" */),
  "component---src-templates-about-us-about-us-tsx": () => import("./../../../src/templates/about-us/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-about-us-tsx" */),
  "component---src-templates-about-us-hakkimizda-tsx": () => import("./../../../src/templates/about-us/hakkimizda.tsx" /* webpackChunkName: "component---src-templates-about-us-hakkimizda-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

