module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-8T843BJHRV","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["tr","en"],"defaultLanguage":"tr","generateDefaultLanguagePage":true,"i18nextOptions":{"keySeparator":".","ignoreJSONStructure":false},"pages":[{"matchPath":"/","languages":["tr","en"]},{"matchPath":"/:lang?/article/:path","languages":["tr","en"]},{"matchPath":"/authors","languages":["tr","en"]},{"matchPath":"/tag","languages":["tr","en"]},{"matchPath":"/404","languages":["tr","en"]},{"matchPath":"/:lang?/about-us/","languages":["en"]},{"matchPath":"/:lang?/author/:path","getLanguageFromPath":true},{"matchPath":"/:lang?/:path","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
